<script>
	import Base from '@backend/Base.vue';
	import BOGen from '@helper/BOGen';
	import Gen from '@helper/Gen';
	import draggable from 'vuedraggable'

	export default {
		name: "BoCapeProcess",
		extends: Base,
		components: {
			draggable,
		},
		data() {
			return {
				Name: "BoCapeProcess",
				sliderDuration: {},
				row2: {},
				data:{data:[]}
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.level'() {
				this.search()
			}
		},
		methods: {
			endDrag() {
				BOGen.apirest('/' + Page.Name, {
					data: this.data.data,
					type: 'sort'
				}, (err, resp) => {
					console.log(resp)
				}, "POST");
			},
			changeSliderDuration(v){
				BOGen.apirest("/"+this.Name, {type:"changeSliderDuration",value:v},(err,resp)=>{
					if(err) swal(err.resp.message,"","warning")
					swal(resp.message,"","success")
				}, "POST")
			},
		}
	};
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<div class="row">
			<div class="col-12" v-if="!$route.params.id">
				<div class="card">
					<div class="card-body">
						<div class="d-flex justify-content-between align-items-center">
							<h5 class="card-title mb-0">{{ObjectName}} List
								<span class="card-subtitle">Drag image to move the position.</span>
							</h5>
						</div>
						<hr>
						<div class="form-row align-items-center mb-4">
							<div class="col-md-2">
								<label>Slider Duration (seconds)</label>
							</div>
							<div class="col-md-1">
								<input type="number" v-model="sliderDuration.ms_value" class="form-control">
							</div>
							<div class="col-md-3">
								<a href="javascript:;" @click="changeSliderDuration(sliderDuration.ms_value)" class="btn btn-success">Save</a>
							</div>
						</div>
						<div class="row">
							<draggable class="col-md-10" v-model="data.data" tag="div" @end="endDrag">
								<div class="item-list border-bottom" v-for="(v,k) in data.data" :key="k">
									<div class="row">
										<div class="col-md-8">
											<h5 class="card-title">{{v.ap_title_en}}</h5>
											<div class="item-desc" v-html="(v.ap_desc_en||'').limitChar(200)"></div>
										</div>
										<div class="col-md-4">
											<div class="product-img">
												<img :src="uploader(v.ap_image)" alt="prod">
												<div class="pro-img-overlay">
													<router-link class="bg-info" v-if="page.moduleRole('Update')" :to="{name:Name,params:{id:v.id}}" v-tooltip="'Edit'"><i class="ti-marker-alt"></i></router-link>
													<a href="javascript:;" v-if="page.moduleRole('Remove')" class="bg-danger" @click="deleteItem($event,k)" v-tooltip="'Remove'"><i class="ti-trash"></i></a>
												</div>
												<span class="label label-success" v-if="v.ap_is_active=='Y'">Active</span>
												<span class="label label-danger" v-else>Inctive</span>
											</div>
										</div>
									</div>
								</div>
							</draggable>
						</div>
						<div class="row" v-if="NotFound">
							<div class="col-12 text-center">
								<h3 class="tc">{{NotFound}}</h3>
							</div>
						</div>
						<div class="row" v-if="data.data===false">
							<div class="col-12 text-center">
								<LoadingSpinner light></LoadingSpinner>
							</div>
						</div>
						<div class="row">
							<div class="col-12">
								<Pagination class="float-right" :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12" v-if="$route.params.id">
				<VForm @resp="submitForm" method="post">
					<div class="card">
						<div class="card-body">
							<div class="d-flex justify-content-between align-items-center">
								<h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
							</div>
							<hr>
							<div class="row">
								<div class="col-12 info"></div>
								<div class="col-md-4">
									<BoField name="ap_image" mandatory>
										<Uploader :param="{thumbnail:true}" name="ap_image" v-model="row.ap_image" type="process"
											uploadType="cropping"></Uploader>
									</BoField>
								</div>
								<div class="col-md-8">
									<div class="row">
										<div class="col-8">
											<BoField name="ap_title_en" v-model="row.ap_title_en" :attr="{placeholder:'e.g Transport'}">
											</BoField>
										</div>
										<div class="col-8">
											<BoField name="ap_title_id" v-model="row.ap_title_id" :attr="{placeholder:'e.g Transport'}">
											</BoField>
										</div>
										<div class="col-12">
											<BoField name="ap_desc_en">
												<CKEditor type='noImage' name="ap_desc_en" v-model="row.ap_desc_en" v-bind="validation('ap_desc_en')">
												</CKEditor>
											</BoField>
										</div>
										<div class="col-12">
											<BoField name="ap_desc_id">
												<CKEditor type='noImage' name="ap_desc_id" v-model="row.ap_desc_id" v-bind="validation('ap_desc_id')">
												</CKEditor>
											</BoField>
										</div>
										<div class="col-8">
											<BoField name="ap_is_active">
												<div class="row">
													<div class="col-2">
														<radio name="ap_is_active" v-model="row.ap_is_active" option="Y" :attr="validation('ap_is_active')">Active</radio>
													</div>
													<div class="col-2">
														<radio name="ap_is_active" v-model="row.ap_is_active" option="N">Inactive</radio>
													</div>
												</div>
											</BoField>
										</div>
									</div>
								</div>
							</div>
							<hr>
							<div class="d-flex justify-content-end">
								<button type="submit" class="btn btn-rounded btn-info btn-loading">Submit</button>
							</div>
						</div>
					</div>
				</VForm>
			</div>
		</div>
	</div>
</template>